import { PortableText } from "@portabletext/react";
import { FeatureList } from "./FeatureList";

const PortableTextFeatureList = ({ value }) => {
  const { items } = value;
  const mapped = items.map((item) => ({
    title: item.title,
    content: <PortableText value={item.content} />,
  }));
  return <FeatureList items={mapped} />;
};

export const PortableTextPlus = ({ value }) => {
  return (
    <PortableText
      // Pass in block content straight from Sanity.io
      value={value}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{}}
      components={{
        types: {
          featureList: PortableTextFeatureList,
        },
      }}
    />
  );
};
