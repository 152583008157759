import * as React from "react";
import { ReactNode } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import * as styles from "./SplitContentImage.module.scss";

interface SplitContentProps {
  reverse?: boolean;
  title: string;
  imageSrc?: string;
  image?: ReactNode;
  contentSize?: "medium" | "large";
  children: React.ReactNode;
}

const SplitContentImage = ({
  reverse,
  title,
  image,
  imageSrc,
  children,
  contentSize,
  inContainer,
}: SplitContentProps) => {
  return (
    <div
      className={`${styles.splitContentImage} ${
        inContainer ? styles[`splitContentImageInContainer`] : ""
      }`}
    >
      <div>
        <div
          className={`row no-gutters radius-10 align-items-center ${
            reverse ? " flex-row-reverse" : ""
          } `}
        >
          <div
            className={`col-lg-12 col-xl-${
              contentSize === "large" ? 4 : 6
            } col-12`}
          >
            <div
              className={`${styles.thumbnail} ${
                styles[`image${reverse ? "Right" : "Left"}Content`]
              }`}
            >
              {image ?? null}
              {imageSrc && <img src={imageSrc} />}
            </div>
          </div>
          <div
            className={`col-lg-12 col-xl-${
              contentSize === "large" ? 8 : 6
            } col-12`}
          >
            <div className={styles.splitInner}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className={styles.title}>{title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                {children}
              </ScrollAnimation>

              {/*<ScrollAnimation*/}
              {/*  animateIn="fadeInUp"*/}
              {/*  animateOut="fadeInOut"*/}
              {/*  animateOnce={true}*/}
              {/*>*/}
              {/*  <ul className="split-list">*/}
              {/*    <li>- Doug DeMarco, Design Prototyping Manager</li>*/}
              {/*    <li>- 108 million paying subscribers</li>*/}
              {/*    <li>- Over 1.7 billion hours of music played monthly</li>*/}
              {/*    <li>- 4,000+ employees working across 16 offices</li>*/}
              {/*  </ul>*/}
              {/*</ScrollAnimation>*/}
              {/*<ScrollAnimation*/}
              {/*  animateIn="fadeInUp"*/}
              {/*  animateOut="fadeInOut"*/}
              {/*  animateOnce={true}*/}
              {/*>*/}
              {/*  <div className="view-more-button mt--35">*/}
              {/*    <a className="btn-default" href="/contact">*/}
              {/*      Contact With Us*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*</ScrollAnimation>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitContentImage;
