import { FiCheck } from "react-icons/fi";
import React from "react";
import "./FeatureList.scss";

export const FeatureList = ({ items }) => {
  return (
    <ul className="feature-list">
      {items.map((item, idx) => (
        <li key={item.id ?? idx}>
          <div className="icon">
            <FiCheck />
          </div>
          <div className="title-wrapper">
            <h4 className="title">{item.title}</h4>
            <p className="text">{item.content}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};
