import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbBanner from "../../components/BreadcrumbBanner";
import SplitContentImage from "../../components/SplitContentImage";
import { PortableText } from "@portabletext/react";
import SanityImage from "gatsby-plugin-sanity-image";
import { SectionSpacer } from "../../feature/blog/layout/SectionSpacer";
import { PortableTextPlus } from "../../components/PortableTextPlus";

const AboutPageTemplate = ({ pageContext }) => {
  const { data } = pageContext;
  const sections = [
    // {
    //   title: data.title,
    //   _rawContent: data._rawContent,
    //   poster: data.poster,
    // },
    ...data.sections,
  ];
  return (
    <>
      <SEO title="About" />
      <Layout>
        <BreadcrumbBanner
          title={data.title}
          rootUrl="/"
          parentUrl="Home"
          currentUrl={data.title}
        />
        <div className="main-content">
          <div className="container">
            <SectionSpacer>
              {sections.map((section, idx) => {
                const { caption, ...posterData } = section.poster ?? {};
                return (
                  <SplitContentImage
                    inContainer
                    title={section.title}
                    image={
                      section.poster ? (
                        <SanityImage
                          {...posterData}
                          alt={caption}
                          className="w-100 radius"
                          width="1280"
                          height="800"
                        />
                      ) : undefined
                    }
                    imageSrc={
                      section.poster
                        ? undefined
                        : `https://picsum.photos/640/480?product-${idx}`
                    }
                    reverse={idx % 2}
                  >
                    <PortableTextPlus
                      value={section._rawContent}
                    ></PortableTextPlus>
                  </SplitContentImage>
                );
              })}
            </SectionSpacer>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutPageTemplate;
