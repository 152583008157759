import React from "react";

const Separator = ({ fullWidth = false }) => {
  if (fullWidth) {
    return <hr className="m-0" />;
  }
  return (
    <div className="container">
      <hr className="m-0" />
    </div>
  );
};

export default Separator;
